import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

class HomePage extends React.Component {
  componentDidMount() {
    this.props.getUsers();
  }

  handleDeleteUser(id) {
    return (e) => this.props.deleteUser(id);
  }

  render() {
    const { user, users } = this.props;
    return (
      <div>
        <h1 style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, .5)' }}>
          Hi {user.displayName}!
        </h1>
        <iframe
          width={window.screen.width - 300}
          height={500}
          src='https://www.youtube.com/embed/LKZcT-syexg'
          frameborder='0'
          allowFullScreen
        ></iframe>
        <button type='button' class='btn btn-success btn-block'>
          <Link style={{color: 'white'}} to='/login'>Logout</Link>
        </button>
      </div>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };
