import LoginModal from './loginModal';
import RegistrationModal from './registrationModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import firebase from '../../_firebase'
class LoginOrRegistration extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { isRegistration: false };
  }

  toggleRegistrationToLogin=()=>{
      this.setState({
        isRegistration: !this.state.isRegistration,
      });
  }

  loginUser=(username, password)=>{
    this.props.login(username, password);
  }


  render() {
    const { loggingIn, loggedIn , alert} = this.props;
    const { isRegistration } = this.state;
    return (
      <div
        style={{backgroundColor: 'rgba(0, 0, 0, 0.8)', overflow: 'hidden', position: 'fixed'}}
        class={"modal fade" + (firebase.auth().currentUser ? " d-none" : " show d-block")}
        id='LoginModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        {alert.message &&
          <div style={{zIndex: 12,}} class='alert alert-danger' role='alert'>
            {alert.message}
          </div>
        }
        {isRegistration ? (
          <RegistrationModal  login={this.toggleRegistrationToLogin} />
        ) : (
          <LoginModal loginUser={this.loginUser} register={this.toggleRegistrationToLogin} />
        )}
      </div>
    );
  }
}
const mapState =(state)=> {
  const { loggingIn, loggedIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, loggedIn, alert };
}

// const actionCreators = {
//   login: userActions.login,
//   logout: userActions.logout
// };

const mapDispatchToProps =(dispatch)=> {
  return ({
    login: (username, password) => dispatch(userActions.login(username, password))
  })
}

export default connect(mapState,mapDispatchToProps )(LoginOrRegistration)
