import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBp57noJYPuufGMVrfJgp1GR483Ea12MjM',
  authDomain: 'hvpl-darsh.firebaseapp.com',
  databaseURL: 'https://hvpl-darsh.firebaseio.com',
  projectId: 'hvpl-darsh',
  storageBucket: 'hvpl-darsh.appspot.com',
  messagingSenderId: '306851052136',
  appId: '1:306851052136:web:3eb026ed6a77d94f8f19dd',
  measurementId: 'G-YDCXDB32G1'
};
firebase.initializeApp(config);
firebase.analytics();
export default firebase;
export const database = firebase.database();
export const db_ref = firebase.database().ref();
export const auth = firebase.auth();
export const functions = firebase.functions();