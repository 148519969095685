import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { registered: true, user } : {};
export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {
        registered: true,
        registeredUser: action.user
      };
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state
  }
}