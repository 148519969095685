import React, { Component } from "react";
import Header from "../_components/Header/header";
import Footer from "../_components/Footer/footer";
import hotSellingProductPreviewData from "../_services/hotSellingProducts.json";
import ProductPreview from "../_components/Product/productPreview";
import postscribe from "postscribe";

export default class LandingPage extends Component {
  componentDidMount() {
    postscribe(
      "#app",
      '<script language="javascript" src="../assets/js/jquery-3.3.1.min.js"></script>'
    );
    postscribe(
      "#app",
      '<script language="javascript" src="../assets/js/plugins.js"></script>'
    );
    postscribe(
      "#app",
      '<script language="javascript" src="../assets/js/main.js"></script>'
    );
  }

  render() {
    return (
      <div>
        <Header key={"LandingPage"} />
        <section class="slider slider-1">
          <div
            class="carousel owl-carousel carousel-arrows carousel-dots"
            data-slide="1"
            data-slide-md="1"
            data-slide-sm="1"
            data-autoplay="true"
            data-nav="true"
            data-dots="true"
            data-space="0"
            data-loop="true"
            data-speed="3000"
            data-transition="fade"
            data-animate-out="fadeOut"
            data-animate-in="fadeIn"
          >
            <div class="slide-item align-v-h bg-overlay">
              <div class="bg-img">
                <img src="./assets/images/sliders/1.jpg" alt="slide img" />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="slide__content">
                      <h2 class="slide__title">
                        Unique value creation with sustainability and
                        authenticity.
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-item align-v-h bg-overlay">
              <div class="bg-img">
                <img src="./assets/images/sliders/2.jpg" alt="slide img" />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="slide__content">
                      <h2 class="slide__title">
                        We believe in creating values and capabilities.{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-item align-v-h bg-overlay">
              <div class="bg-img">
                <img src="./assets/images/sliders/3.jpg" alt="slide img" />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="slide__content">
                      <h2 class="slide__title">
                        High Integrity and developing premium products.{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-item align-v-h bg-overlay">
              <div class="bg-img">
                <img src="./assets/images/sliders/4.jpg" alt="slide img" />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="slide__content">
                      <h2 class="slide__title">
                        Superior performance outcomes{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-item align-v-h bg-overlay">
              <div class="bg-img">
                <img src="./assets/images/sliders/5.jpg" alt="slide img" />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="slide__content">
                      <h2 class="slide__title">
                        We believe in operational excellence, customer intimacy,
                        and product development.{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="aboutLayout2" class="about about-layout2 pt-120 pb-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7">
                <div class="heading mb-50">
                  <h2 class="heading__title">
                    Harsons already has its wings spread for success since 30+
                    years in this industry. Worlds leading supplier of
                    Automotive components. Harsons specializes in Manufacturing
                    anything and everything.
                  </h2>
                  <h6 class="heading__title" style={{ fontSize: 20, fontWeight: 250,}}>
                    The company’s operations are <b><strong>ISO/TS 16949:2016 certified</strong></b>,<br/>
                    ensuring superior engineered products.
                  </h6>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <p>
                      Based on the high quality products and the best service
                      concept, Harsons has desinged its product, service and the
                      way of doing business in accordance with customer demands
                      and expectations. To have the highest level of customer
                      satisfaction has been our priority. That's why the
                      production quality level of Harsons is defined at the
                      highest limits. Harsons, who made "to improve innovation
                      continuously" as a principle, manufactures suitable with
                      international quality standards and advanced analysis
                      techniques. Harsons guarantee the products are
                      manufactured in accordance with the original standards and
                      also provide easiness of mounting.
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 mt-60">
                    <div class="about__text mr-30">
                      <p></p>
                    </div>
                    <img
                      src="assets/images/about/Signature.svg"
                      alt="singnture"
                      class="signature"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-10 col-lg-8 col-xl-5 position-static">
                <div class="row mt-50 about__imgs-container">
                  <div class="col-7">
                    <div class="about__img">
                      <img
                        src="assets/images/about/2.jpg"
                        alt="about"
                        class="img-fluid w-100"
                      />
                      <div class="cta__banner">
                        <div class="cta__icon">
                          <i class="icon-factory-2"></i>
                        </div>
                        <h5 class="cta__title mb-0">
                          Building The Future, Restoring The Past
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="about__img mt-40" style={{ opacity: 0.5 }}>
                      <img
                        src="assets/images/about/3.jpg"
                        alt="about"
                        class="img-fluid w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="featuresNumberd" class="features-numberd pt-0">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div
                  class="carousel owl-carousel carousel-dots"
                  data-slide="5"
                  data-slide-md="3"
                  data-slide-sm="1"
                  data-autoplay="true"
                  data-nav="false"
                  data-dots="true"
                  data-space="30"
                  data-loop="false"
                  data-speed="700"
                >
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-eco"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">01</h3>
                    <h4 class="feature__numberd-item-title">
                      Environmental <br />
                      Sensitivity
                    </h4>
                  </div>
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-worker"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">02</h3>
                    <h4 class="feature__numberd-item-title">
                      Professional & <br />
                      Qualified{" "}
                    </h4>
                  </div>
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-management"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">03</h3>
                    <h4 class="feature__numberd-item-title">
                      Personalised <br />
                      Solutions
                    </h4>
                  </div>
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-parcel"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">04</h3>
                    <h4 class="feature__numberd-item-title">
                      Transparent <br /> Pricing
                    </h4>
                  </div>
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-gear"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">05</h3>
                    <h4 class="feature__numberd-item-title">
                      100% Satisfaction <br />
                      Guarantee
                    </h4>
                  </div>
                  <div class="feature-numberd-item">
                    <div class="feature__numberd-item-icon">
                      <i class="icon-monitor"></i>
                    </div>
                    <h3 class="feature__numberd-item-number">06</h3>
                    <h4 class="feature__numberd-item-title">
                      Accurate <br />
                      Testing
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="bannerLayout1" class="banner banner-layout1 p-0">
          <div class="container-fluid col-padding-0">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 bg-theme">
                <div class="inner-padding">
                  <div class="heading heading-3 heading-white mb-50">
                    <h2 class="heading__title">
                      Harsons is recognized as one of the World's leading
                      Industry & Manufacturing Corporation!
                    </h2>
                    <p class="heading__desc">
                      Harsons has been building relationships that last, serving
                      impressive list of long-term clients with expertise in
                      multiple industries.
                    </p>
                  </div>
                  <ul class="list-items list-items-white list-unstyled mb-50">
                    <li>Quality Control System</li>
                    <li>Unrivalled workmanship</li>
                    <li>100% Satisfaction Guarantee</li>
                    <li>Professional and Qualified</li>
                    <li>Highly Professional Staff</li>
                    <li> Environmental Sensitivity</li>
                    <li>Accurate Testing Processes</li>
                    <li>Personalised solutions</li>
                  </ul>
                  {/* <a href='#' class='btn btn__white btn__bordered btn__lg'>
                    Schedule An Appointment
                  </a> */}
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 bg-parallax">
                <div class="bg-img">
                  <img src="assets/images/banners/3.jpg" alt="background" />
                </div>
                <div class="vertical-align-center">
                  <div class="video__btn video__btn-white">
                    <a
                      class="popup-video"
                      href="https://www.youtube.com/watch?v=Xx0grGUibi4"
                    >
                      <span class="video__player-animation"></span>
                      <span class="video__player-animation video__player-animation-2"></span>
                      <div class="video__player">
                        <i class="fa fa-play"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="portfolioCarousel" class="portfolio-carousel pt-120 pb-90">
          <div class="container">
            {/* <div class='row heading heading-2 mb-40'>
              <div class='col-sm-12 col-md-12 col-lg-6'>
                <h2 class='heading__title'>
                  Providing Innovative and Sustainable Solutions.
                </h2>
              </div>
            </div> */}
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div
                  class="carousel owl-carousel carousel-arrows"
                  data-slide="3"
                  data-slide-md="2"
                  data-slide-sm="1"
                  data-autoplay="false"
                  data-nav="true"
                  data-dots="true"
                  data-space="30"
                  data-loop="false"
                  data-speed="800"
                >
                  {hotSellingProductPreviewData.map((product) => {
                    return <ProductPreview image={product.imageURL} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          id='bannerLayout4'
          class='banner banner-layout4 bg-overlay bg-parallax'
        >
          <div class='bg-img'>
            <img src='assets/images/banners/6.jpg' alt='background' />
          </div>
          <div class='container'>
            <div class='row'>
              <div class='col-sm-12 col-md-12 col-lg-7'>
                <div class='heading heading-5 heading-white mb-40'>
                  <span class='heading__subtitle'>
                    Dedicated Customer Teams & An Agile Services
                  </span>
                  <h3 class='heading__title'>
                    Serving Impressive List Of Long Term Clients!
                  </h3>
                  <p class='heading__desc'>
                    Our worldwide presence ensures timeliness, cost efficiency
                    and compliance adherence required to ensure your timelines
                    are met. Serving with experience aNd expertise in multiple
                    industries, as one of the World's leading Corporation!
                  </p>
                </div>
                <a href='#' class='btn btn__primary btn__link'>
                  <i class='icon-arrow-right'></i>{' '}
                  <span>Download our Brochure</span>
                </a>
              </div>
              <div class='col-sm-12 col-md-12 col-lg-4 offset-lg-1'>
                <blockquote class='blockquote'>
                  You are helping to lead the charge. We can help you build on
                  your past successes and prepare for your great future.
                  <span class='quote__author'>- Martin, koira</span>
                </blockquote>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
        <button id="scrollTopBtn">
          <i class="fa fa-long-arrow-up"></i>
        </button>
        {/* <Script url="assets/js/jquery-3.3.1.min.js" />
        <Script url="assets/js/plugins.js" />
        <Script url="assets/js/main.js" /> */}
      </div>
    );
  }
}
