import React, { Component }  from 'react';

export default function Footer(props) {
    return (
      <footer id='footer' class='footer'>
        <div class='footer-bottom'>
          <div class='container'>
            <div class='row'>
              <div class='col-sm-12 col-md-3 col-lg-3'>
                <img src='assets/images/logo/logo-light.png' alt='logo' />
              </div>
              <div class='col-sm-12 col-md-9 col-lg-9 text-right'>
                <div class='footer__copyright'>
                  <nav>
                    <ul class='footer__copyright-links list-unstyled d-flex flex-wrap justify-content-end'>
                      <li>
                        <ul class="social__icons">
                        <li><a href="https://www.facebook.com/HVPL2018/"><i class="fa fa-facebook"></i></a></li>
                        {/* <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter"></i></a></li> */}
                        <li><a href="https://in.linkedin.com/company/harsons-ventures-pvt-ltd"><i class="fa fa-linkedin"></i></a></li>
                      </ul>
                      </li>
                    </ul>
                  </nav>
                  <p class='mb-0'>&copy; 2020 Harsons Ventures Private. Limited. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }