import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavLink from '../NavigationLink/nav_link';
import { Router, Route, BrowserRouter, Redirect } from 'react-router-dom';
import LandingPage from '../../LandingPage/LandingPage';
import { Contacts } from '../../_Contacts';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
class Header extends Component {

  getName=(displayName)=>{
    let name = displayName;
    if(displayName.length > 20){
       name = displayName.substring(0,20) + ' ...';
    }
    return name;
  }

  render() {
    const { notTransparent, user } = this.props;
    let headerClassName = notTransparent ? 'header' : 'header header-transparent'
    return (
      <header key={this.props.key} id='header' className={headerClassName}>
        <div
          style={{
            position: 'absolute',
            right: 0,
            width: 170,
            height: 18,
            zIndex: 1
          }}
          id='translation'
        ></div>
        <nav className='navbar navbar-expand-lg sticky-navbar'>
          <div className='container'>
            <a className='navbar-brand' href='/'>
              <img
                src='assets/images/logo/logo-light.png'
                className='logo-light'
                alt='logo'
              />
              <img
                src='assets/images/logo/logo-dark.png'
                className='logo-dark'
                alt='logo'
              />
            </a>
            <button className='navbar-toggler' type='button'>
              <span className='menu-lines'>
                <span></span>
              </span>
            </button>
            <div className='collapse navbar-collapse' id='mainNavigation'>
              <ul className='navbar-nav ml-auto'>
                {/* <li className='nav__item'>
                  <NavLink to='/'>Home</NavLink>
                </li> */}

                <li className='nav__item'>
                  <NavLink to='/products'>Products</NavLink>
                </li>
                <li className='nav__item'>
                  <NavLink to='/contacts'>Contact Us</NavLink>
                </li>
                <li className={'nav__item'}>
                  {user && user.displayName ? (
                    <a href={'/'} onClick={this.props.logout}>
                      <NavLink to='/'>
                        {this.getName(user.displayName)}{' '}
                        <i class='fa fa-sign-out' style={{paddingLeft: 5}} />
                      </NavLink>
                    </a>
                  ) : (
                    <NavLink to='/products'>
                      <i class='fa fa-sign-in' style={{paddingRight: 5}} /> Login 
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
            <div className='navbar-modules'>
              <ul className='list-unstyled d-flex align-items-center modules__btns-list'>
                <li className='d-none d-lg-block'>
                  <div class='module__btn module__btn-phone d-flex align-items-center'>
                    <i class='icon-phone'></i>
                    <a href='tel:18008892644'>1800 889 2644</a>
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  logout: userActions.logout
};

export default connect(mapState, actionCreators )(Header)
