import React from 'react';

export default function ProductPreview(props) {
    return (
      <div class='portfolio-item'>
        <div class='portfolio__img'>
          <img src={props.image} alt='portfolio img' />
        </div>
      </div>
    );
  }