import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import Header from '../_components/Header/header';
import Footer from '../_components/Footer/footer';
import postscribe from 'postscribe';
import PhoneInput from 'react-phone-input-2';
import { history } from '../_helpers';
import { addEnquiriesToDb } from '../_services';

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
      website: '',
      submitted: false,
      alertMessage: {
        code: '',
        message: ''
      },
      enquiryMessage: '',
      inValidData: {
        inValid: false,
        message: ''
      },
      sending: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

   handleSubmit(e) {
    e.preventDefault();
    let isOtherDataValid = this.otherFieldValidation();
    if (isOtherDataValid) {
      this.setState({
        inValidData: {},
        sending: true
      });
      let {firstName, lastName, email, phone, enquiryMessage, website, companyName} = this.state;
      firstName = firstName.trim();
      lastName = lastName.trim();
      email = email.trim();
      phone = phone.trim();
      enquiryMessage.trim();
      website = website.trim();
      companyName = companyName.trim();
      let user = {
        email: email,
        enquiryMessage: enquiryMessage,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        website: website,
        companyName: companyName
      };
      addEnquiriesToDb(user).then(result=>{
        this.setState({
          submitted: false,
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phone: '',
          website: '',
          submitted: false,
          sending: false,
          enquiryMessage: '',
          inValidData: {},
          alertMessage: {
            code: 200,
            message: 'Query Sent!'
          },
        });
      }).catch(error=>{
        this.setState({
          submitted: false,
          sending: false,
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phone: '',
          website: '',
          submitted: false,
          enquiryMessage: '',
          inValidData: {},
          alertMessage: {
            code: 503,
            message: 'Please try again after sometime!'
          },
        });
      })
    }
  }


  otherFieldValidation = () => {
    let {firstName, lastName, companyName, email, phone} = this.state;
    firstName = firstName.trim();
    lastName = lastName.trim();
    companyName = companyName.trim();
    email = email.trim();
    phone = phone.trim();
    let notEnteredText = null;
    if (!firstName) {
      notEnteredText = 'First Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!lastName) {
      notEnteredText = 'Last Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!email) {
      notEnteredText = 'Email is required!';
      return this.setValidationResponse(notEnteredText);
    } else if (!this.validateEmail(email)) {
      notEnteredText = 'Please enter valid email!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!companyName) {
      notEnteredText = 'Company Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!phone) {
      notEnteredText = 'Phone number is required!';
      return this.setValidationResponse(notEnteredText);
    }
    return true;
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  setValidationResponse = notEnteredText => {
    if (notEnteredText) {
      let inValidData = {
        inValid: true,
        message: notEnteredText
      };
      this.setState({
        inValidData: inValidData
      });
      return false;
    } else {
      return true;
    }
  };


  initMap() {
    const google = window.google;

    // The location of Uluru
    var uluru = { lat: 22.971905, lng: 72.729566 };
    // The map, centered at Uluru
    var map = new google.maps.Map(
        document.getElementById('map'), {
        zoom: 12, center: uluru,
        styles: [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
    });
    // The marker, positioned at Uluru
    var marker = new google.maps.Marker({ title: 'Harson Ventures PVT. LTD', position: uluru, map: map });
}

  componentDidMount(){
  postscribe('#app', '<script language="javascript" src="../assets/js/main.js"></script>')
  postscribe('#app', '<script language="javascript" src="../assets/js/jquery-3.3.1.min.js"></script>');
  postscribe('#app', '<script language="javascript" src="../assets/js/plugins.js"></script>')
  postscribe('#map', '<script language="javascript" src="../assets/js/google-map.js"></script>')
  this.initMap()
}

  render() {
    const { registering, alert } = this.props;
    const {
      firstName,
      lastName,
      companyName,
      email,
      enquiryMessage,
      website,
      phone,
      inValidData,
      alertMessage,
      sending
    } = this.state;
    return (
      <div>
        <Header key={'Contacts'} notTransparent={true} />
        <section id='googleMap' className='google-map p-0'>
          <div id='map' style={{height: 440, width: '100%'}}></div>
        </section>
        <section
          id='contactLayout1'
          className='contact contact-layout1 pt-0'
          style={{
            backgroundColor: 'white'
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='contact__panel'>
                  <div className='contact__panel-banner'>
                    <img src='assets/images/banners/2.jpg' alt='banner img' />
                    <div className='cta__banner'>
                      <p className='cta__desc'>
                        <strong>
                          We will get back to you within 24 hours, or you can
                          call us Mon-Fri, 09:00 AM - 06:00 PM
                        </strong>
                      </p>
                      <div class='contact__number d-flex align-items-center'>
                        <i class='icon-phone'></i>
                        <a href='tel:18008892644'>1800 889 2644</a>
                      </div>
                    </div>
                  </div>
                  <form className='contact__form-panel' onSubmit='return false'>
                    {inValidData.inValid && (
                      <div class='alert alert-danger' role='alert'>
                        {inValidData.message}
                      </div>
                    )}
                    {alertMessage.code === 200 ? (
                      <div class='alert alert-success alert-dismissible'>
                        <a
                          href='#'
                          class='close'
                          data-dismiss='alert'
                          aria-label='close'
                        >
                          &times;
                        </a>
                        Query Submitted successfully!
                      </div>
                    ) : alertMessage.code === 503 ? (
                      <div class='alert alert-danger alert-dismissible'>
                        <a
                          href='#'
                          class='close'
                          data-dismiss='alert'
                          aria-label='close'
                        >
                          &times;
                        </a>
                        Oops something went wrong! Please try again after
                        sometime.
                      </div>
                    ) : null}
                    <div className='row'>
                      <div className='col-sm-12 contact__form-panel-header'>
                        <h4>Get In Touch</h4>
                        <p>
                          Complete control over products allows us to ensure our
                          customers receive the best quality prices and service.
                        </p>
                      </div>
                      <div className='col-sm-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            value={firstName}
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({firstName: value});
                            }}
                            type='text'
                            className='form-control'
                            placeholder='First Name*'
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6'>
                        <div className='form-group'>
                          <input
                            value={lastName}
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({lastName: value});
                            }}
                            type='text'
                            className='form-control'
                            placeholder='Last Name*'
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <div className='form-group'>
                          <input
                            required
                            value={email}
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({email: value});
                            }}
                            type='email'
                            className='form-control'
                            placeholder='Email*'
                          />
                        </div>
                      </div>

                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <div className='form-group'>
                          <input
                            value={website}
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({website: value});
                            }}
                            type='text'
                            className='form-control'
                            placeholder='Website(optional)'
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <div className='form-group'>
                          <input
                            value={companyName}
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({companyName: value});
                            }}
                            type='text'
                            className='form-control'
                            placeholder='Company Name*'
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <div className='form-group'>
                          <input
                            style={{display: 'none'}}
                            value={phone}
                            type='text'
                            className='form-control'
                          />
                          <PhoneInput
                            country={'in'}
                            containerClass={'form-control'}
                            buttonStyle={{
                              backgroundColor: 'transparent',
                              borderWidth: 0
                            }}
                            value={phone}
                            inputStyle={{
                              borderWidth: 0,
                              backgroundColor: 'transparent',
                              top: 5
                            }}
                            onChange={phone => this.setState({phone})}
                          />
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-group'>
                          <textarea
                            onChange={e => {
                              const {value} = e.target;
                              this.setState({enquiryMessage: value});
                            }}
                            value={enquiryMessage}
                            className='form-control'
                            placeholder='Write to us!'
                          ></textarea>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <button
                          onClick={this.handleSubmit}
                          className='btn btn__secondary btn__block'
                        >
                          {sending ? ( //need to changes
                            <span
                              class='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            <div>
                              <span>Submit Query</span>
                              <i className='icon-arrow-right'></i>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='contactInfo' className='contact contact-info pt-0'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='contact-info-box'>
                  <h4 className='contact__info-box-title'>Factory Unit</h4>
                  <ul className='contact__info-list list-unstyled'>
                    <li>
                      Email: &nbsp;
                      <a href='milto:info@hvplindia.com'>info@hvplindia.com</a>
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href='milto:export@harsons.in'>export@harsons.in</a>
                    </li>
                    <li>
                      Address: 43 NK-1 Industrial Estate Nr Gatrad, Bakrol
                    </li>
                    <li>
                      {' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ahmedabad
                      (Gujarat) INDIA
                    </li>
                    <li>Phone: 1800 889 2644</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <button id='scrollTopBtn'>
          <i className='fa fa-long-arrow-up'></i>
        </button>
      </div>
    );
  }
}


function mapState(state) {
  const {registering} = state.registration;
  const { alert } = state;
  return {registering, alert};
}

const actionCreators = {
  register: userActions.register,
};

export default connect(mapState, actionCreators)(Contacts);
