import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import  Contacts  from '../_Contacts/Contacts';
import LandingPage from '../LandingPage/LandingPage';
import Products from '../Products/ProductsGrid';
// import {browserHistory} from 'react-router';

class App extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });

    if (window.performance) {
      if (performance.navigation.type == 1) {
        history.push('/');
      }
    }
  }

  render() {
    const { alert } = this.props;
    return (
      <div>
        <Router history={history}>
          <div>
            <Switch>
              {/* <PrivateRoute exact path='/' component={HomePage} />
            <Route path='/login' component={LoginPage} />
            <Route path='/register' component={RegisterPage} /> */}

              <Route path='/contacts' component={Contacts} />
              <Route path='/products' component={Products} />
              <Route path='/' component={LandingPage} />
              <Redirect from='*' to='/' />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
