import { authHeader } from '../_helpers';
import firebase, { functions }from '../_firebase';

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  getCategory,
  getProducts
};

function login(username, password) {
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      return firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then(async (user) => {
          if (user) {
            localStorage.setItem('user', JSON.stringify(user.user));
            return user.user;
          }
        });
    });
}

function setUser(user, displayName) {
  let userDetails = {
    email: user.email,
    displayName: user.displayName ? user.displayName : displayName,
    phoneNumber: user.phone,
    photoURL: user.photoURL,
    uid: user.uid
  };
  return userDetails;
}

function logout() {
  // remove user from local storage to log user out
  firebase
    .auth()
    .signOut()
    .then(function () {
      localStorage.removeItem('user');
    })
    .catch(function (error) {
      // An error happened.
    });
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`/users`, requestOptions).then(handleResponse);
}

async function getCategory() {
  const snapshot = await firebase.firestore().collection('CATEGORY').get();
  return snapshot.docs.map(doc => {
    return { uid: doc.id, ...doc.data() }
  });
}

async function getProducts() {
  const snapshot = await firebase.firestore().collection('ONLY_PRODUCTS').get();
  return snapshot.docs.map(doc => {
    return { uid: doc.id, ...doc.data() }
  });
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(registeringUser) {
  const { email, firstName, lastName, password } = registeringUser;
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      return firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          let currentUser = firebase.auth().currentUser;
          if (user) {
              let displayName =`${firstName} ` + `${lastName}`;
            currentUser.updateProfile({
              displayName: displayName,
            });
            let userDetails = setUser(user.user, displayName);
            registeringUser.uid = user.user.uid;
            registeringUser.emailVerified = user.user.emailVerified;
            addUserDetails(registeringUser, displayName);
            localStorage.setItem('user', JSON.stringify(userDetails));
            return userDetails;
          }
        });
    });
}

function addUserDataToDb(userData){
    let addUser = functions.httpsCallable('addUser');
    let params = userData;
    addUser(params).then(function (result) {
       console.log(result);
   })
   .catch(function (error) {
       console.log(JSON.stringify(error));
   });
}

export async function  addEnquiriesToDb(enquiryData){
  let addEnquiries = functions.httpsCallable('addEnquiries');
  let params = enquiryData;
  return addEnquiries(params);
}


function addUserDetails(user, displayName){
    let userDetail={
      email: user.email,
      displayName: displayName,
      emailVerified: user.emailVerified,
      uid: user.uid,
      phoneNumber: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      website: user.website,
      companyName: user.companyName,
      photoURL: user.photoURL
    }
    addUserDataToDb(userDetail);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
