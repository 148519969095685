import React, {Component} from 'react';
import firebase, {database} from '../../_firebase';
import {connect} from 'react-redux';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyEnquired: false
    };
  }

  sendEnquiry = title => {
    try {
      let user = firebase.auth().currentUser;
      database.ref('products/' + user.uid).push(title);
    } catch (error) {}
  };

  componentDidMount() {
    const { user } = this.props;
    this.readData(user);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if(!this.props.viewer && nextProps.viewer){
      this.readData(nextProps.viewer);
    }
  }

  readData = (user) => {
    try {
      const { title } = this.props;
      let self = this;
      var starCountRef = database.ref('products/' + user.uid);
      starCountRef.on('value', function (snapshot) {
        if (snapshot.val()) {
          const alreadyEnquiredData = Object.values(snapshot.val());
          if (alreadyEnquiredData) {
            let alreadyEnquired = alreadyEnquiredData.includes(title);
            self.setState({alreadyEnquired});
          }
          // propertyValues.map((data, index)=>{
          //     console.log(`data===>${index}`, data);

          // })
        }
      });
    } catch (error) {
      console.log('error on get', error);
    }
  };

  render() {
    const {image, description, title} = this.props;
    return (
      <div class='col-sm-6 col-md-6 col-lg-4'>
        <div class='portfolio-item'>
          <div class='portfolio__img'>
            <img src={image} alt='portfolio img' />
          </div>
          <div class='portfolio__content'>
            <h4 class='portfolio__title'>
              <a>{title}</a>
            </h4>
            {/* <p class='portfolio__desc'>{description}</p> */}
            {this.state.alreadyEnquired ? (
              <div class='btn btn__secondary' style={{marginTop: 5}}>
                <span>Enquired</span>
                <i class='icon-envelope'></i>
              </div>
            ) : (
              <div
                onClick={() => this.sendEnquiry(title)}
                class='btn btn__primary'
              >
                <span>Enquire Now</span>
                <i class='icon-envelope'></i>
              </div>
            )}
          </div>
          {/* <div class='divider__line divider__lg'></div> */}
        </div>
      </div>
    );
  }
}

const mapState = state => {
  const {user} = state.authentication;
  return {user};
};

export default connect(mapState, null)(Product);
