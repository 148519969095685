import React, {Component} from 'react';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeUserName = e => {
    const {name, value} = e.target;
    this.setState({username: value});
  };

  handleChangePassword = e => {
    const {name, value} = e.target;
    this.setState({password: value});
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({submitted: true});
    const {username, password} = this.state;
    if (username && password) {
      this.props.loginUser(username, password);
    }
  }

  onCloseModal=()=>{
    history.push('/');
  }
  
  render() {
    const {username, password, submitted} = this.state;
    const {loggingIn} = this.props;
    return (
      <div class='modal-dialog' role='document'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='portfolio__title' id='exampleModalLabel'>
              Login to view all available products
            </h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.onCloseModal}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div class='modal-body'>
            <form onSubmit='return false'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='username'
                  value={username}
                  onChange={this.handleChangeUserName}
                />
              </div>
              <div className='form-group'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='password'
                  value={password}
                  onChange={this.handleChangePassword}
                />
              </div>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <button
                  onClick={this.handleSubmit}
                  disabled={!(username && password)}
                  type='submit'
                  className='btn btn__secondary btn__block'
                >
                  {loggingIn ? (
                    <span
                      class='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <div>
                      <span>Login</span>
                      <i className='icon-arrow-right'></i>{' '}
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
          <div class='modal-footer'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <a
                // href='/'
                onClick={this.props.register}
                class='btn btn__secondary btn__block'
              >
                <span>Register</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState =(state)=> {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

export default connect(mapState, null )(LoginModal)
