import React, {Component} from 'react';
import Header from '../_components/Header/header';
import Footer from '../_components/Footer/footer';
import Product from '../_components/Product/product';
import data from './ProductListData.json';
import LoginOrRegistration from '../_components/Login/LoginOrRegistration';
import {connect} from 'react-redux';
import postscribe from 'postscribe';
import Collapsible from 'react-collapsible';
import firebase from '../_firebase'
import { userService } from '../_services';
var _ = require('lodash');

class Products extends Component {
  constructor(props){
    super(props);
    this.state={
      productViewer: '',
      category: [],
      sortedProducts: [],
      loader: true
    }
  }

  componentDidMount() {
    let self = this;
    firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {
        let category = await userService.getCategory();
        let products = await userService.getProducts();
        let sortedProducts = _.groupBy(products, function(product){return product.categoryId})
        self.setState({
          category,
          sortedProducts,
          productViewer: user,
          loader: false
        });
      } else {
        // No user is signed in.
      }
    });
    postscribe('#app', '<script language="javascript" src="../assets/js/jquery-3.3.1.min.js"></script>');
    postscribe('#app', '<script language="javascript" src="../assets/js/plugins.js"></script>')
    postscribe('#app', '<script language="javascript" src="../assets/js/main.js"></script>')
  }


  render() {
    const { loader } = this.state;

    return (
      <div>
        <Header notTransparent={true} key={'ProductGrid'} />
        <section id='faq' class='faq pt-120'>
          <div
            class='container'
            style={{filter: this.props.user ? null : 'blur(5px)'}}
          >
           {loader ? <h3>Loading Data...</h3> : null}
            <div class='row' id='accordion'>
              <div class='col-sm-12 col-md-12 col-lg-12'>
                {/* ONE ITEM */}
                {this.state.category.map((category, index) => {
                  return (
                    <div id={category.id} className={`accordion-item`}>
                      <div class='accordion__item-header'>
                        <Collapsible
                          // open={index === 0}
                          triggerClassName='accordion__item-title'
                          className='accordion__item-header'
                          trigger={category.categoryName}
                          triggerOpenedClassName={'accordion__item-title'}
                        >
                          <section
                            style={{paddingBottom: 10, paddingTop: 20}}
                            id='portfolioGrid'
                            class='portfolio portfolio-grid'
                          >
                            <div class='row'>
                              <p
                                style={{paddingTop: 5, paddingBottom: 30, paddingRight: 16, paddingLeft: 16}}
                                className={'heading__desc'}
                              >
                                <b>
                                {category.categoryDesc}
                                </b>
                              </p>
                              {this.state.sortedProducts[category.id] && this.state.sortedProducts[category.id].map(product => {
                                return (
                                  <Product
                                    viewer={this.state.productViewer}
                                    title={product.title}
                                    description={product.description}
                                    image={product.imageURL}
                                  />
                                );
                              })}
                            </div>
                          </section>
                        </Collapsible>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <LoginOrRegistration isRegistration={false} />
      </div>
    );
  }
}

const mapState = state => {
  const {loggedIn, user} = state.authentication;
  return {loggedIn, user};
};

export default connect(mapState, null)(Products);
