import React, {Component} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { userActions } from '../../_actions';
import { history } from '../../_helpers';
import { connect } from 'react-redux';

class RegistrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password1: '',
      password2: '',
      phone: '',
      website: '',
      submitted: false,
      inValidData: {
        inValid: false,
        message: ''
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let isPasswordsValid = this.passwordValidation();
    let isOtherDataValid = this.otherFieldValidation();
    if (isOtherDataValid && isPasswordsValid) {
      this.setState({
        inValidData: {}
      });
      let {firstName, lastName, email, phone, password1, password2, website, companyName} = this.state;
      firstName = firstName.trim();
      lastName = lastName.trim();
      email = email.trim();
      phone = phone.trim();
      password1 = password1.trim();
      password2 = password2.trim();
      website = website.trim();
      companyName = companyName.trim();
      let user = {
        email: email,
        password: password1,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        website: website,
        companyName: companyName
      };
      this.props.register(user)
    }
  }

  passwordValidation = () => {
    let {password1, password2} = this.state;
    password1 = password1.trim();
    password2 = password2.trim();
    if (password1.length >= 6) {
      if (password1 !== password2) {
        let message = 'Password must match!';
        return this.setValidationResponse(message);
      }
    } else if (password1.length !== 0 && password1.length < 6) {
      let message = 'Password length must be greater or equal to 6!';
      return this.setValidationResponse(message);
    } else if (password1.length === 0) {
      let message = 'Password is required!';
      return this.setValidationResponse(message);
    }
    return true;
  };

  otherFieldValidation = () => {
    let {firstName, lastName, companyName, email, phone} = this.state;
    firstName = firstName.trim();
    lastName = lastName.trim();
    companyName = companyName.trim();
    email = email.trim();
    phone = phone.trim();
    let notEnteredText = null;
    if (!firstName) {
      notEnteredText = 'First Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!lastName) {
      notEnteredText = 'Last Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!email) {
      notEnteredText = 'Email is required!';
      return this.setValidationResponse(notEnteredText);
    } else if (!this.validateEmail(email)) {
      notEnteredText = 'Please enter valid email!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!companyName) {
      notEnteredText = 'Company Name is required!';
      return this.setValidationResponse(notEnteredText);
    }
    if (!phone) {
      notEnteredText = 'Phone number is required!';
      return this.setValidationResponse(notEnteredText);
    }
    return true;
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  setValidationResponse = notEnteredText => {
    if (notEnteredText) {
      let inValidData = {
        inValid: true,
        message: notEnteredText
      };
      this.setState({
        inValidData: inValidData
      });
      return false;
    } else {
      return true;
    }
  };

  onCloseModal=()=>{
    history.push('/');
  }

  render() {
    const {
      firstName,
      lastName,
      companyName,
      email,
      password1,
      password2,
      website,
      phone,
      inValidData
    } = this.state;
    const {registering} = this.props;
    return (
      <div class='modal-dialog' role='document'>
        {inValidData.inValid && (
          <div class='alert alert-danger' role='alert'>
            {inValidData.message}
          </div>
        )}
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='portfolio__title' id='exampleModalLabel'>
              Sign-up to view all available products
            </h5>
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.onCloseModal}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div class='modal-body'>
            <form className='contact__form-panel' onSubmit='return false'>
              <div className='row'>
                <div className='col-sm-6 col-md-6'>
                  <div className='form-group'>
                    <input
                      value={firstName}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({firstName: value});
                      }}
                      type='text'
                      className='form-control'
                      placeholder='First Name*'
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6'>
                  <div className='form-group'>
                    <input
                      value={lastName}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({lastName: value});
                      }}
                      type='text'
                      className='form-control'
                      placeholder='Last Name*'
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      required
                      value={email}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({email: value});
                      }}
                      type='email'
                      className='form-control'
                      placeholder='Email*'
                    />
                  </div>
                </div>

                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      value={website}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({website: value});
                      }}
                      type='text'
                      className='form-control'
                      placeholder='Website(optional)'
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      value={companyName}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({companyName: value});
                      }}
                      type='text'
                      className='form-control'
                      placeholder='Company Name*'
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      style={{display: 'none'}}
                      value={phone}
                      type='text'
                      className='form-control'
                    />
                    <PhoneInput
                      country={'in'}
                      containerClass={'form-control'}
                      buttonStyle={{
                        backgroundColor: 'transparent',
                        borderWidth: 0
                      }}
                      value={phone}
                      inputStyle={{
                        borderWidth: 0,
                        backgroundColor: 'transparent',
                        top: 5
                      }}
                      onChange={phone => this.setState({phone})}
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      value={password1}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({password1: value});
                      }}
                      minLength={6}
                      required
                      type='password'
                      className='form-control'
                      placeholder='Choose password*'
                    />
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <div className='form-group'>
                    <input
                      required
                      minLength={6}
                      value={password2}
                      onChange={e => {
                        const {value} = e.target;
                        this.setState({password2: value});
                      }}
                      type='password'
                      className='form-control'
                      placeholder='Confirm password*'
                    />
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <button
                    onClick={this.handleSubmit}
                    className='btn btn__secondary btn__block'
                  >
                    {registering ? (
                      <span
                        class='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <div>
                        <span>Sign up</span>
                        <i className='icon-arrow-right'></i>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class='modal-footer'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <a
                onClick={this.props.login}
                class='btn btn__secondary btn__block'
              >
                <span>Login</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const {registering} = state.registration;
  return {registering};
}

const actionCreators = {
  register: userActions.register
};

export default connect(mapState, actionCreators)(RegistrationModal);
